import { ToastContainer } from "react-toastify";
import { ColorPalette } from "./util/const";
import {
  DropDownMenu,
  PrimaryBtn,
  RColumn,
  RRow,
  Space,
  SpaceHorizontal,
  Title1,
  Title2,
} from "./comps/otherCOmps";
import { useEffect, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import InventarioPage from "./inventario/inventarioPage";
import { useTheme } from "./theme";
import VerifichePage from "./verifiche/verifichePage";
import Dashboard from "./dashboard/dashboardPage";
import { SelectTremor } from "./comps/coolComp";
import logFC from "./assetts/fcLogoTesto.png";
import logoFCOnly from "./assetts/firecheck_stondato.png";
import { Button, Callout, Icon } from "@tremor/react";
import {
  Ri4kFill,
  RiArrowLeftLine,
  RiBuilding2Line,
  RiCommunityLine,
  RiFile2Line,
  RiGroup2Line,
  RiGroupLine,
  RiHomeOfficeLine,
  RiLineChartLine,
  RiLogoutBoxLine,
  RiSettings2Line,
  RiToolsLine,
} from "@remixicon/react";
import BannerMessage from "./comps/banner";
import UtentiPage from "./utenti/utentiPage";
import SettingsPage from "./settings/settingsPage";
import DashboardSedePage from "./dashboardSede/dashboardSedePage";
import { Caches } from "./caches/caches";
import { getScadenzaPiano } from "./util/util";
import { logOut } from "./firebase";

export default function PagesContainer() {
  const [currentPage, setCurrentPage] = useState(0);

  const { setNewSede, setNewSedeWithEnpoint } = useTheme();

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const { sedeSelected } = useTheme();

  const sediOptions = Object.values(Caches.baseInfoData).map((s) => ({
    label: s.info.nome,
    value: s.endpoint,
  }));

  const [sedeSelectOpt, setSedeSelectOpt] = useState(sediOptions[0]);
  const [isInScadenza, setIsInScadenza] = useState({ value: false, in: 0 });

  useEffect(() => {
    setSedeSelectOpt({
      label: sedeSelected.nome,
      value: sedeSelected.endpoint,
    });

    const giorniMancantiPiano = getScadenzaPiano(sedeSelected.endpoint);

    setIsInScadenza({
      value: giorniMancantiPiano <= 30 && giorniMancantiPiano > 0,
      in: giorniMancantiPiano,
    });
  }, [sedeSelected]);

  function handleCambioSede(newSedeOpt) {
    setSedeSelectOpt(newSedeOpt);
    setNewSedeWithEnpoint(newSedeOpt.value);
  }

  function SingleBtn({ tit, href, icon = Ri4kFill }) {
    return (
      <NavLink
        style={{ width: "100%" }}
        to={href}
        className={({ isActive }) =>
          isActive ? "active-link-sidebar" : "inactive-link-sidebar"
        }
      >
        <RRow width={"100%"} horizontalAlign="start">
          <Icon icon={icon} variant="simple" color="black" />
          {isMenuOpen && <SpaceHorizontal />}
          {isMenuOpen ? tit : null}
        </RRow>
      </NavLink>
    );
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      <div
        style={{
          overflowY: "auto",
          zIndex: 10,
          position: "fixed",
          width: isMenuOpen ? "260px" : "80px",
          height: "100vh",
          backgroundColor: ColorPalette.bgColorLight,
          top: 0,
          left: 0,
        }}
        className="animate"
      >
        <Space height="12px" />
        <RColumn
          verticalAlignment="space-between"
          height={"95%"}
          padding={isMenuOpen ? "0px 22px" : null}
        >
          <RColumn
            width={"100%"}
            horizontalAlign={isMenuOpen ? "start" : "center"}
            verticalAlignment="start"
          >
            <RColumn>
              <div style={{ height: "100px" }}>
                {!isMenuOpen && <Space height="18px" />}
                <img
                  src={isMenuOpen ? logFC : logoFCOnly}
                  width={isMenuOpen ? "140px" : "22px"}
                />
              </div>
              <SingleBtn tit={"Sedi"} href={"/"} icon={RiBuilding2Line} />
              <SingleBtn
                tit={"Dashboard"}
                href={"/dashboard"}
                icon={RiLineChartLine}
              />
              <SingleBtn
                tit={"Inventario"}
                href={"/inventario"}
                icon={RiToolsLine}
              />
              <SingleBtn
                tit={"Verifiche"}
                href={"/verifiche"}
                icon={RiFile2Line}
              />
              <SingleBtn tit={"Utenti"} href={"/utenti"} icon={RiGroupLine} />
            </RColumn>
          </RColumn>

          <RRow
            width={"100%"}
            horizontalAlign={!isMenuOpen ? "center" : "start"}
          >
            <RColumn horizontalAlign="start">
              <SingleBtn
                tit={"Impostazioni"}
                href={"/impostazioni"}
                icon={RiSettings2Line}
              />
              <Space />
              <div style={{ cursor: "pointer" }}>
                <RiArrowLeftLine
                  className="animate"
                  style={{ rotate: isMenuOpen ? "0deg" : "180deg" }}
                  onClick={() => setIsMenuOpen((prev) => !prev)}
                />
              </div>
            </RColumn>
          </RRow>
        </RColumn>
        <Space />
      </div>
      <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
        <div
          className="animate"
          style={{ width: isMenuOpen ? "270px" : "100px" }}
        ></div>
        <div style={{ flex: 1 }}>
          <div style={{ padding: "12px 18px" }}>
            <RRow horizontalAlign="end">
              <SelectTremor
                options={sediOptions}
                width="300px"
                value={sedeSelectOpt}
                onSelect={handleCambioSede}
              />
            </RRow>
            {isInScadenza.value && (
              <BannerMessage
                onPrimaryBtnClick={() =>
                  window.open("https://firecheck.it/contatti/", "_blank")
                }
                primaryBtnTxt={"Contatta servizio clienti"}
                tit={"Piano in scadenza"}
                desc={`Il piano Fire Check scade tra ${isInScadenza.in} giorni, contatta il servizio clienti per un rinnovo`}
              />
            )}
          </div>

          <div style={{ padding: "4px 12px" }}>
            {isInScadenza.in <= 0 ? (
              <Callout title="Piano scaduto">
                <RColumn horizontalAlign="start">
                  <span>
                    Il tuo abbonamento FireCheck è scaduto, contatta il servizio
                    clienti per un rinnovo
                  </span>
                  <Space />
                  <RRow>
                    <button
                      onClick={() =>
                        window.open("https://firecheck.it/contatti/", "_blank")
                      }
                      type="button"
                      className="w-auto whitespace-nowrap rounded-tremor-small bg-tremor-brand px-4 py-2 text-tremor-default font-medium text-tremor-brand-inverted shadow-tremor-input hover:bg-tremor-brand-emphasis dark:bg-dark-tremor-brand dark:text-dark-tremor-brand-inverted dark:shadow-dark-tremor-input dark:hover:bg-dark-tremor-brand-emphasis"
                    >
                      Contatta servizio clienti
                    </button>
                    <SpaceHorizontal />
                    <Button
                      variant="secondary"
                      icon={RiLogoutBoxLine}
                      onClick={() => logOut()}
                    >
                      Esci
                    </Button>
                  </RRow>
                </RColumn>
              </Callout>
            ) : (
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route
                  exact
                  path="/dashboard"
                  element={<DashboardSedePage />}
                />
                <Route exact path="/inventario" element={<InventarioPage />} />
                <Route exact path="/verifiche" element={<VerifichePage />} />
                <Route exact path="/utenti" element={<UtentiPage />} />
                <Route exact path="/impostazioni" element={<SettingsPage />} />
              </Routes>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
